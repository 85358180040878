<template>
  <v-app>

    <v-navigation-drawer app right v-model="sideNav" disable-resize-watcher style="z-index: 9999999">
      <v-list class="navlist font-weight-bold">
        <v-list-item link v-for="(item, i) in navItems" :key="i" :to="item.route">
          <v-list-item-title class="text-uppercase">{{ item.name }}</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{name: 'Deelnemen'}">
          <v-list-item-title>DEELNEMEN</v-list-item-title>
        </v-list-item>
        <v-list-item link href="https://portal.classactionverhuurderheffing.nl/login" target="_blank">
          <v-list-item-title>INLOGGEN</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="sticky-placehold" v-show="sticky"></div>
    <v-toolbar color="transparent" height="80" max-height="80" :class="{sticky, ...navbarClasses}" v-scroll="onScroll" ref="nav" id="navbar" style="position:relative;">
      <v-container style="max-width: 1240px" class="pr-0">
        <v-row no-gutters>
          <v-col cols="9" md="2" class="d-flex align-center">
            <router-link to="/">
            <v-img
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo.svg')"
                transition="scale-transition"
                width="170"
            />
            </router-link>
          </v-col>
          <v-col class="d-flex align-center justify-end text-right" style="font-size: 12px">
            <template v-if="$vuetify.breakpoint.lgAndUp">
              <router-link v-for="(item, idx) in navItems" :key="idx"
                  :to="item.route"
                  class="text-uppercase font-weight-bold mr-4 text-center"
                           exact-active-class="route-active"
                  style="font-style: normal">{{ item.name }}</router-link>

              <router-link :to="{name: 'Deelnemen'}" class="text-uppercase font-weight-bold mr-4 white--text primary pa-1" style="font-style: normal">Deelnemen</router-link>
              <a href="https://portal.classactionverhuurderheffing.nl/login" target="_blank" class="text-uppercase font-weight-bold mr-3 white--text primary pa-1" style="font-style: normal">Inloggen</a>
            </template>
            <v-app-bar-nav-icon v-else @click="sideNav = !sideNav"></v-app-bar-nav-icon>
          </v-col>

        </v-row>
      </v-container>
    </v-toolbar>

    <v-main>
      <router-view :key="$route.path"/>
    </v-main>

    <v-footer color="white">
      <v-container style="max-width: 1240px">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-row no-gutters>
              <v-col cols="12" sm="8">
                <div class="d-flex align-center">
                  <v-img
                      class="shrink mr-6"
                      :src="require('@/assets/CA.svg')"
                      transition="scale-transition"
                      contain
                      width="40"
                  />
                  <span style="font-size: 14px"><a :href="require('@/assets/Disclaimer.pdf')" target="_blank" style="font-style: normal">Disclaimer</a>
              |
              <a :href="require('@/assets/privacyverklaring-Jeremia.pdf')" target="_blank" style="font-style: normal">Privacyverklaring</a></span>
                </div>

              </v-col>
            </v-row>


          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-end align-center mt-3 mt-md-0">
            <v-row no-gutters>
              <v-col cols="7" md="4"><strong>Telefoon: 0181 - 45 95 15</strong></v-col>
              <v-col><strong>E-mail: <a href="mailto:info@classactionverhuurderheffing.nl" style="font-style: normal">info@classactionverhuurderheffing.nl</a></strong></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    sticky: false,
    originalPos: 0,
    sideNav: false,
    activeNav: 0,
    navItems: [
      /*{name: 'Home', route: {name: 'Home', hash: ''}},
      {name: 'Over ons', route: {name: 'About'}},
      {name: 'Actueel', route: {name: 'Nieuws'}},
      {name: 'Jeremia', route: {name: 'Home', hash: '#jeremia'}},
      {name: 'Activiteiten', route: {name: 'Home', hash: '#activiteiten'}},
      {name: 'Class Action', route: {name: 'Home', hash: '#classaction'}},
      {name: 'Corporaties', route: {name: 'Corporaties'}},
      {name: 'Procedure', route: {name: 'Home', hash: '#procedure'}},
      {name: 'Veelgestelde Vragen', route: {name: 'Faq'}},
      {name: 'Contact', route: {name: 'Contact'}},*/
      {name: 'Home', route: {name: 'Home', hash: ''}},
      {name: 'Verhuurderheffing', route: {name: 'About'}},
      {name: 'Actueel', route: {name: 'Nieuws'}},
      {name: 'Corporaties', route: {name: 'Corporaties'}},
      {name: 'Jeremia', route: {name: 'Jeremia'}},
      {name: 'Frame Vastgoed', route: {name: 'Framevastgoed'}},
      {name: 'Veelgestelde Vragen', route: {name: 'Faq'}},
      {name: 'Contact', route: {name: 'Contact'}},
    ]
  }),
  methods: {
    onScroll() {
      let rect = this.$refs.nav.$el.getBoundingClientRect();
      if (window.scrollY === 0)
        this.sticky = false;
      if (rect.bottom < 0 && !this.sticky)
        this.sticky = true;
      else if (this.sticky && window.scrollY < this.originalPos)
        this.sticky = false;
    },
    isCurrentRoute(item) {
      let resolved = this.$router.resolve(item);
      if (!resolved.resolved) return false;
      return resolved.route.path === this.$router.currentRoute.path;
    }
  },
  watch: {
    sticky(/*newVal, oldVal*/) {
      //if (newVal !== oldVal)
        //this.$refs.navtabs.onResize();
    }
  },
  computed: {
    navbarClasses() {
      return this.$vuetify.breakpoint.smAndDown ? {'no-border': true} : {};
    }
  }
};
</script>
<style>
  #navbar.v-toolbar.sticky {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    z-index: 10000 !important;
    background-color: rgba(255, 255, 255, 0.97) !important;
  }

  .sticky-placehold {
    height: 80px;
  }

  .sticky-placehold:before {
    content: "";
  }

  #navbar {
    z-index: 99999;
  }

  .route-active {
    border-bottom: 1px solid black;
  }
</style>
