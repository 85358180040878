<template>
  <v-container style="max-width: 1240px" class="mt-0 pt-0">
    <nav-anchor id="home" @trigger="onIntersect"></nav-anchor>
    <v-card color="#F6F6F3" flat>
      <v-row no-gutters>
        <v-col cols="12" md="7">
          <v-carousel height="100%">
            <v-carousel-item :src="require('@/assets/CA-1.jpg')"></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" md="5">
          <v-card-text class="pa-10 main-card">
            <h1 class="pb-10">De verhuurderheffing verhindert de benodigde investeringen</h1>
            <p>
              In het jaar 2013 is de verhuurderheffing in het leven geroepen: een ingrijpende belastingmaatregel die alleen aan verhuurders van sociale huurwoningen werd opgelegd. De verhuurderheffing was ooit bedoeld als crisismaatregel, om een gat in de Rijksbegroting te vullen.
            </p>
            <router-link :to="{name: 'About'}">Lees verder >></router-link>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="mt-16" v-if="newsItem">
      <v-row no-gutters>
        <v-col cols="12" sm="4" md="3" offset-md="1" class="d-flex align-center">
          <v-img :src="newsItem.featured_image" :aspect-ratio="1"></v-img>
        </v-col>
        <v-col cols="12" sm="8" md="8">
          <v-card-text class="pl-md-16">
            <h1 class="pb-10">{{ newsItem.title }}</h1>
            <p v-html="newsItem.excerpt"></p>
            <router-link :to="{name: 'Nieuws'}">Lees verder >></router-link>
          </v-card-text>
          <nav-anchor id="jeremia" @trigger="onIntersect"></nav-anchor>
        </v-col>
      </v-row>
    </v-card>

    <fade-in-transition>
      <v-row no-gutters class="mt-16">
        <v-col cols="12" lg="12">
          <v-card flat color="#F6F6F3">
            <v-row no-gutters>

              <v-col cols="12" sm="8" lg="9">
                <v-card-text class="pa-10">

                  <h1 class="pb-10">Class Action</h1>
                  <p>Vanaf 2019 is er een substantiële groep van deelnemers binnen de Class
                    Action geformeerd bestaande uit corporaties en private verhuurders van
                    sociale huurwoningen. Deze groep is tot op heden gegroeid tot 57
                    deelnemingen. De totale omvang van verhuurderheffing (over de jaren
                    2019, 2020, 2021 en 2022) die met de Class Action gemoeid is bedraagt op
                    dit moment rond de 155 miljoen euro. De class action is voor de
                    deelnemers op basis van ‘no-cure-no-pay’. De deelnemers betalen een succesfee, 
                    afhankelijk van de omvang van de verhuurderheffing die
                    bij een positieve uitspraak van de rechter wordt betaald.</p>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="4" lg="3" class="d-flex align-center">
                <v-img :src="require('@/assets/Doelstelling.jpg')" :aspect-ratio="1" :contain="$vuetify.breakpoint.smAndDown" height="100%" ></v-img>
              </v-col>
            </v-row>
          </v-card>
          <nav-anchor id="doelstelling" name="doelstelling" @trigger="onIntersect"></nav-anchor>
        </v-col>
      </v-row>
    </fade-in-transition>



    <fade-in-transition>
      <nav-anchor id="procedure" @trigger="onIntersect"></nav-anchor>
    <v-row class="mt-16">
      <v-col cols="12" md="4">
        <v-hover v-slot="{ hover }">
        <v-card flat color="#F6F6F3" height="100%">

            <v-img
                :src="require('@/assets/Iconen Class Action website-02.svg')"
                max-width="320px"
                class="ml-auto mr-auto"
                v-if="hover"
            ></v-img>

          <v-img
              :src="require('@/assets/Iconen Class Action website-01.svg')"
              max-width="320px"
              class="ml-auto mr-auto"
              v-else
          ></v-img>

          <v-card-text>
            <p class="text-center">
              De collectieve verhuurderheffing procedure is in het jaar 2019 van start
              gegaan. De Class Action is in behandeling bij de Belastingrechter van
              Rechtbank Gelderland. Ten aanzien van de zitting in mei 2022 heeft de
              meervoudige belastingkamer de beroepen ongegrond verklaard. Jeremia
              is hiertegen in beroep gegaan.
            </p>
          </v-card-text>
        </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot="{ hover }">
        <v-card flat color="#F6F6F3" height="100%">
          <v-img
              :src="require('@/assets/Iconen Class Action website-03.svg')"
              max-width="320px"
              class="ml-auto mr-auto"
              v-if="hover"
          ></v-img>

          <v-img
              :src="require('@/assets/Iconen Class Action website-04.svg')"
              max-width="320px"
              class="ml-auto mr-auto"
              v-else
          ></v-img>
          <v-card-text>
            <p class="text-center">
              Deelname aan de Class Action Verhuurderheffing is op basis van no cure no pay.
              Alle kosten voor de procedure worden gedragen door Jeremia. Indien de
              procedure succesvol verloopt en de deelnemer verhuurderheffing krijgt
              terugbetaald, wordt daarvan een succesfee afgedragen aan Jeremia.
            </p>
          </v-card-text>
        </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot="{ hover }">
        <v-card flat color="#F6F6F3" height="100%">
          <v-img
              :src="require('@/assets/Iconen Class Action website-06.svg')"
              max-width="320px"
              class="ml-auto mr-auto"
              v-if="hover"
          ></v-img>

          <v-img
              :src="require('@/assets/Iconen Class Action website-05.svg')"
              max-width="320px"
              class="ml-auto mr-auto"
              v-else
          ></v-img>
          <v-card-text>
            <p class="text-center">
              Deelname is gezien de voortgang in de procedure niet meer mogelijk.
            </p>
            
          </v-card-text>
        </v-card>
        </v-hover>
      </v-col>
    </v-row>
    </fade-in-transition>

  </v-container>
</template>

<script>

  import FadeInTransition from "@/components/FadeInTransition";
  import NavAnchor from "@/components/NavAnchor";
  import http from "@/http";
  export default {
    name: 'Home',

    components: {
      NavAnchor,
      FadeInTransition
    },
    data() {
      return {
        lastIntersect: null,
        newsItem: null
      }
    },
    methods: {
      onIntersect(e) {
        if (this.lastIntersect !== e) {
          window.location.hash = '#' + e;
          this.lastIntersect = e;
        }
      }
    },
    async mounted() {
      let resp = await http.get(`/p/nl/nieuws?count=1`);
      this.newsItem = resp.data.data[0];
      this.$nextTick(() => {
        if (this.$route.hash) {
          let bounds = document.querySelector(this.$route.hash).getBoundingClientRect();
          console.log(bounds);
          window.scrollTo({
            top: bounds.top,
            behavior: 'smooth',
          });
        }
      })

    }
  }
</script>

<style>
.main-card p, .main-card a {
  font-size: 20px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}

a:not(.v-list-item) {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
  font-style: italic;
}

.v-card__subtitle, .v-card__text {
  color: #202020 !important;
}
</style>
