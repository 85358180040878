<template>
  <section :style="sectionStyles" :class="sectionClasses"><slot></slot></section>
</template>

<script>
export default {
  name: "FadeInTransition",
  data() {
    return {
      inScreen: false,
      observer: null
    }
  },
  computed: {
    sectionStyles() {
      return {opacity: 0, position: 'relative'}
    },
    sectionClasses() {
      return {trans: this.inScreen}
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.$el) {
          this.inScreen = entry.isIntersecting;
        }
      });
    });
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer.unobserve(this.$el);
    this.observer = null;
  }
}
</script>

<style scoped>

@keyframes example {
  from {
    transform: scale(0.95, 0.95);
    top: 45px;
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    top: 0;
    opacity: 1;
  }
}

.trans {
  animation: example;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}
</style>
