<template>
  <a :id="id"></a>
</template>

<script>
export default {
  name: "NavAnchor",
  props: ['id'],
  data() {
    return {
      inScreen: false,
      observer: null
    }
  },
  methods: {
    triggerOn() {
      if (window.location.hash !== '#' + this.id)
        history.replaceState(null, null, document.location.pathname + '#' + this.id);
    },
    scrolling() {
      setTimeout(() => {
        let rect = this.$el.getBoundingClientRect();
        let half = window.innerHeight / 2;
        if (rect.y > half - 50 && rect.y < half + 50)
          this.triggerOn();
      }, 300);
    }
  },
  mounted() {
    /*this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.$el) {
          this.inScreen = entry.isIntersecting;
          let rect = this.$el.getBoundingClientRect();
          if (this.inScreen && rect.y < 250)
            this.triggerOn();
        }
      });
    });
    this.observer.observe(this.$el);*/
    window.addEventListener('scroll', this.scrolling);
  },
  beforeDestroy() {
    //this.observer.unobserve(this.$el);
    //this.observer = null;
    window.removeEventListener('scroll', this.scrolling);
  }
}
</script>

<style scoped>

</style>
